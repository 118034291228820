import React from "react";
import styled from "styled-components";
import {
    bodyColor,
    containerMaxWidth,
    headerHeight
} from "./theme";
import Watch from "./pages/watch";
import {Route, Switch} from "react-router-dom";
import Register from "./pages/user/register";
import Login from "./pages/user/login";
import Logout from "./pages/user/logout";
import Store from "../store";
import _ from "lodash";
import AddCamera from "./pages/camera/add-camera";
import Notice from "./pages/notice";
import LogoutFinish from "./pages/user/logoutFinish";
import UserMovieList from "./pages/user/userMovieList";
import Dashboard from "./pages/user/dashboard";
import PersonMovieList from "./pages/user/personMovieList";
import CarImageList from "./pages/user/CarImageList";
import Privacy from "./pages/privacy";
import IconButton from "@material-ui/core/IconButton";
import Button from '@material-ui/core/Button';
import MenuIcon from "@material-ui/icons/Menu";

import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BarChartIcon from "@material-ui/icons/BarChart";
import PetsIcon from "@material-ui/icons/Pets";
import Badge from "@material-ui/core/Badge";
import CamcoderIcon from "@material-ui/icons/Videocam";
import PeopleIcon from "@material-ui/icons/NotificationsActive";
import Hidden from "@material-ui/core/Hidden";
import KeyIcon from "@material-ui/icons/VpnKey";
import {history} from "../history";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";


const drawerWidth = 240;

const AppWrapper = styled.div`
  font-family: "Spoqa Han Sans";
`;
const Container = styled.div`
  max-width: ${containerMaxWidth}px;
  margin: 0 auto;
`;

const FooterContainer = styled.div`
  max-width: ${containerMaxWidth}px;
  width : auto;
  display : flex;
  item-aligns: center;
  justify-content: center;
  margin: 0 auto;
`;

const Copyright = styled.div`
  font-size: 12px;
  margin-left : 30px ;
  padding-top : 10px;
`;

const Footer = styled.div`
  border-top: 1px solid ${bodyColor};
  padding: 10px 0;
`;

const UserTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: ${headerHeight}px;
  padding-right: 10px;
`;

const styles = theme => ({
    root: {
        display: "flex"
    },
    appBar: {
        marginLeft: drawerWidth,
        zIndex : 2,
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`
        }
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    drawerPaper: {
        width: drawerWidth,
        zIndex : 2,
    },
    textField: {
        marginLeft: theme.spacing.unit*1,
        marginRight: theme.spacing.unit*1,
        width: '100%',
    },

    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        paddingTop: 30,
        paddingBottom: 64,
        marginTop: 30,
        marginLeft: 30,
        [theme.breakpoints.up("sm")]: {
            marginLeft: 280
        }
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up("sm")]: {
            display: "none"
        }
    },
    grow: {
        flexGrow: 1
    }

});

class App extends React.Component {
    /*shouldComponentUpdate(nextProps, nextState) {
        return (JSON.stringify(this.state.countData) !== JSON.stringify(nextState.countData)||
                this.state.currentUser !== nextState.currentUser
        );
    }*/

    constructor(props) {
        super(props);

        this.state = {
            currentUser: "",
            countData: [],
            movieCount: "",
            carCount: "",
            personCount: "",
            store: new Store(this),
            mobileOpen: false,
            dialogOpen : false,
            changePassword : "",
            changePasswordConfirm : "",
            changePasswordError : "",
        };

        this._onMoiveClick = this._onMoiveClick.bind(this);
        this._onDashboardClick = this._onDashboardClick.bind(this);
        this._onWatchClick = this._onWatchClick.bind(this);
        this._onPersonMoiveClick = this._onPersonMoiveClick.bind(this);
        this._onCarImageClick = this._onCarImageClick.bind(this);
        this._onPasswordOpen = this._onPasswordOpen.bind(this);
        this._onPasswordClose = this._onPasswordClose.bind(this);
        this._onPasswordChange = this._onPasswordChange.bind(this);
    }

    componentWillMount() {
        const {store} = this.state;
        document.title = "횡성 축사 관리 홈페이지";
        this._loadCurrentUser(store);
        this.timerID = setInterval(() => this.updateData(), 1000);

    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    _onPasswordChange = name => event =>{
    const value = event.target.value;
    this.setState({[name]: value});
    };

    _onPasswordOpen(event){
        this.setState({dialogOpen:true});
    }

    _onPasswordClose(event){
        this.setState({dialogOpen:false});
        this.setState({
            changePassword : "",
            changePasswordConfirm : "",
            changePasswordError : "",
        })
    }

    _passwordChange(event){
        const {store} = this.state;
        const {currentUser} = this.state;
        if(this.state.changePassword === this.state.changePasswordConfirm){
            store.changePassword(currentUser._id,this.state.changePassword);
            this._onPasswordClose();
        } else {
            this.setState({changePasswordError : "비밀번호가 일치하지 않습니다."})
        }
    }

    _onMoiveClick(event) {
        history.push("/watch");
    }

    _onPersonMoiveClick(event) {
        history.push("/person");
    }

    _onDashboardClick(event) {
        history.push("/dashboard");
    }

    _onCarImageClick(event) {
        history.push("/car");
    }

    _onPrivacyClick(event) {
        history.push("/privacy")
    }

    handleDrawerToggle = () => {
        this.setState(state => ({mobileOpen: !state.mobileOpen}));
    };

    _onWatchClick(event) {
        const userID = _.get(this.state.currentUser, '_id', '');
        history.push(`/watch/${userID}`);
    }

    _loadCurrentUser(store) {
        store.getCurrentUser((res) => {
            this._setCurrentUser(res);
        });
    }

    _setCurrentUser(currentUser) {
        this.setState({currentUser});
    }

    updateData() {
        /*const {store} = this.state;
        const countData = store.getCount();
        console.log("updateData in app");
        if (JSON.stringify(this.state.countData) !== JSON.stringify(countData)) {
            const countArray = countData.toArray();
            const movieCount = _.get(countArray[0], "movie", "0");
            const personCount = _.get(countArray[1], "person", "0");
            const carCount = _.get(countArray[2], "car", "0");
            this.setState({
                countData: countData,
                movieCount: movieCount,
                personCount: personCount,
                carCount: carCount
            });
        }
        if (!this.state.currentUser || this.state.currentUser === undefined) {
            clearInterval(this.timerID);
        }*/
    }

    render() {

        this.updateData();
        const {store} = this.state;
        const {movieCount} = this.state;
        const {personCount} = this.state;
        const {currentUser} = this.state;
        const {classes, theme} = this.props;
        const drawer = (
            <div>
                <div className={classes.toolbar}/>
                <Divider/>
                <List>
                    <div>
                        <ListItem button onClick={this._onDashboardClick}>
                            <ListItemIcon>
                                <BarChartIcon/>
                            </ListItemIcon>
                            <ListItemText primary="통계 그래프"/>
                        </ListItem>
                        <ListItem button onClick={this._onWatchClick}>
                            <ListItemIcon>
                                <CamcoderIcon/>
                            </ListItemIcon>
                            <ListItemText primary="실시간 CCTV"/>
                        </ListItem>
                        <ListItem button onClick={this._onMoiveClick}>
                            <ListItemIcon>
                                {movieCount === 0 ||
                                movieCount === "" ||
                                movieCount === "0" ||
                                movieCount === undefined ?
                                    <PetsIcon/> : (
                                        <Badge
                                            badgeContent={movieCount}
                                            color="secondary"
                                        >
                                            <PetsIcon/>
                                        </Badge>
                                    )}
                            </ListItemIcon>
                            <ListItemText primary="승가 영상"/>
                        </ListItem>
                        <ListItem button onClick={this._onPersonMoiveClick}>
                            <ListItemIcon>
                                {personCount === 0 ||
                                personCount === "" ||
                                personCount === "0" ||
                                personCount === undefined
                                    ? <PeopleIcon/> : (
                                        <Badge
                                            badgeContent={personCount}
                                            color="secondary"
                                        >
                                            <PeopleIcon/>
                                        </Badge>
                                    )}
                            </ListItemIcon>
                            <ListItemText primary="사람 & 자동차"/>
                        </ListItem>
                        <ListItem button onClick ={this._onPasswordOpen}>
                            <ListItemIcon>
                                <KeyIcon/>
                            </ListItemIcon>
                            <ListItemText primary="비밀번호 변경"/>
                        </ListItem>
                    </div>
                </List>
            </div>
        );

        return (
            <AppWrapper>
                <div className={classes.root}>
                    <CssBaseline/>
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.handleDrawerToggle}
                                className={classes.menuButton}
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Typography
                                className={classes.grow}
                                component="h6"
                                variant="h6"
                                color="inherit"
                                noWrap
                            >
                                횡성 축사 관리 페이지
                            </Typography>
                            {currentUser ? <Logout store={store}/> : null}
                            {currentUser ? (
                                <UserTitle>
                                    <Typography
                                        component="h6"
                                        variant="h6"
                                        color="inherit"
                                        noWrap
                                    >
                                        {_.get(currentUser, "name", "")}
                                    </Typography>
                                </UserTitle>
                            ) : null}
                        </Toolbar>
                    </AppBar>
                    <nav
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        anchor="left"
                    >
                        <Hidden smUp implementation="css">
                            <Drawer
                                container={this.props.container}
                                variant="temporary"
                                anchor={theme.direction === "rtl" ? "right" : "left"}
                                open={this.state.mobileOpen}
                                onClose={this.handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper
                                }}
                            >
                                {drawer}
                            </Drawer>
                        </Hidden>
                        <Hidden xsDown implementation="css">
                            <Drawer
                                classes={{
                                    paper: classes.drawerPaper
                                }}
                                variant="permanent"
                                open
                            >
                                {drawer}
                            </Drawer>
                        </Hidden>
                    </nav>
                </div>
                <main className={classes.content}>
                    <Dialog
                        open={this.state.dialogOpen}
                        onClose={this._onPasswordClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"비밀번호를 변경하시겠습니까?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {this.state.changePasswordError}
                                <TextField
                                    id="password"
                                    type="password"
                                    label="새 비밀번호"
                                    className={classes.textField}
                                    value={this.state.changePassword}
                                    onChange={this._onPasswordChange('changePassword')}
                                    margin="normal"
                                />
                                <TextField
                                    id="confirm"
                                    label="새 비밀번호 확인"
                                    type="password"
                                    className={classes.textField}
                                    value={this.state.changePasswordConfirm}
                                    onChange={this._onPasswordChange('changePasswordConfirm')}
                                    margin="normal"
                                />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this._onPasswordClose} color="primary">
                                취소
                            </Button>
                            <Button onClick={() => {
                                this._passwordChange();
                            }} color="primary" autoFocus>
                                확인
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <div className={classes.toolbar}/>
                    <Container>
                        <Switch>
                            <Route path="/reload" component={null} key="reload"/>
                            <Route
                                exact
                                path={"/notice"}
                                render={routeProps => (
                                    <Notice {...routeProps} store={store}/>
                                )}
                                />
                            <Route
                                exact
                                path={"/dashboard/camera/add"}
                                render={routeProps => (
                                    <AddCamera {...routeProps} store={store}/>
                                )}
                            />

                            <Route
                                exact
                                path={"/login"}
                                render={routeProps => <Login {...routeProps}
                                                             updateUser={this._setCurrentUser.bind(this)}
                                                             store={store}/>}
                            />
                            <Route
                                exact
                                path={"/logout"}
                                render={routeProps => (
                                    <LogoutFinish
                                        {...routeProps}
                                        store={store}
                                        updateUser={this._setCurrentUser.bind(this)}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={"/register"}
                                render={routeProps => (
                                    <Register {...routeProps} store={store}/>
                                )}
                            />
                            <Route
                                exact
                                path={"/watch/:id"}
                                render={routeProps => <Watch
                                    {...routeProps} store={store}
                                    updateData={this.updateData.bind(this)}
                                />}
                            />
                            <Route
                                exact
                                path={"/watch/"}
                                render={routeProps => (
                                    <UserMovieList
                                        {...routeProps} store={store}
                                        updateData={this.updateData.bind(this)}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={"/person/"}
                                render={routeProps => (
                                    <PersonMovieList
                                        {...routeProps} store={store}
                                        updateData={this.updateData.bind(this)}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={"/car/"}
                                render={routeProps => (
                                    <CarImageList {...routeProps} store={store}/>
                                )}
                            />
                            <Route
                                exact
                                path={"/"}
                                render={routeProps => <Login {...routeProps} store={store}/>}
                            />
                            <Route
                                exact
                                path={"/privacy"}
                                render={routeProps => <Privacy{...routeProps}/>}
                            />
                            <Route
                                exact
                                path={"/dashboard"}
                                render={routeProps => (
                                    <Dashboard
                                        {...routeProps}
                                        store={store}
                                        currentUser={currentUser}
                                        updateData={this.updateData.bind(this)}
                                        />
                                )}
                            />
                        </Switch>
                    </Container>
                </main>
                <Footer className={classes.content}>
                    <FooterContainer>
                        <Button className={classes.button} onClick={this._onPrivacyClick}>
                            개인정보처리방침
                        </Button>
                        <Copyright> SSNetworks / SUNGHO, YUN</Copyright>
                    </FooterContainer>
                </Footer>
            </AppWrapper>
        );
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles, {withTheme: true})(App);
