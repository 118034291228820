import React, {Component} from "react";
import styled from "styled-components";
import _ from 'lodash'
import {withStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import TablePagination from '@material-ui/core/TablePagination';

import EditIcon from "@material-ui/icons/Edit";
import TrashIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const UserCameraWrapper = styled.div``;

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
});

class UserCamera extends Component {
    shouldComponentUpdate(nextProps, nextState) {
        return JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data)
            || (this.props.editIdx !== nextProps.editIdx)
            || (this.state.page !== nextState.page)
            || (this.state.rowsPerPage !== nextState.rowsPerPage)
            || (this.state.cameraDialogOpen !== nextState.cameraDialogOpen)
            ;
    }

    constructor(props) {
        super(props);
        this.state = {
            cameraDialogOpen: false,
            page: 0,
            rowsPerPage: 5,
            editCamera: ''
        };
    }

    handleChangePage = (event, page) => {
        this.setState({page});
    };

    handleChangeRowsPerPage = event => {
        this.setState({page: 0, rowsPerPage: event.target.value});
    };


    cameraHandleClickOpen = cam => {
        this.setState({
            cameraDialogOpen: true,
            editCamera: cam
        });
    };

    cameraHandleClose = () => {
        this.setState({cameraDialogOpen: false});
    };

    render() {
        const {classes} = this.props;
        const {header} = this.props;
        const {startEditing} = this.props;
        const {editIdx} = this.props;
        const {handleChange} = this.props;
        const {handleRemove} = this.props;
        const {stopEditing} = this.props;
        const {data} = this.props;
        const {rowsPerPage, page} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        const row = (
            x,
            i,
            header,
            startEditing,
            editIdx,
            handleChange,
            handleRemove,
            stopEditing
        ) => {
            let currentlyEditing = editIdx === i;
            return (
                <TableRow key={`tr-${i}`} selectable="false">
                    {header.map((y, k) => (
                        <TableCell key={`trc-${k}`}>
                            {currentlyEditing ? (
                                <TextField
                                    name={y.prop}
                                    onChange={e => handleChange(e, y.prop, _.get(x, 'phone'), _.get(x, 'name'), _.get(x, 'cameraIp'))}
                                    value={x[y.prop]}
                                />
                            ) : (
                                x[y.prop]
                            )}
                        </TableCell>
                    ))}
                    <CustomTableCell>
                        {currentlyEditing ? (
                            <CheckIcon onClick={() => stopEditing(x)}/>
                        ) : (
                            <EditIcon onClick={() => startEditing(i)}/>
                        )}
                    </CustomTableCell>
                    <CustomTableCell>
                        <TrashIcon onClick={() => this.cameraHandleClickOpen(x)}/>
                    </CustomTableCell>
                </TableRow>
            );
        };
        return (
            <UserCameraWrapper>
                <div>
                    <Dialog
                        open={this.state.cameraDialogOpen}
                        onClose={this.cameraHandleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"해당 카메라를 삭제 하시겠습니까?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                카메라 이름 : {<span>{_.get(this.state.editCamera, 'name', '')}<br/></span>}
                                핸드폰 번호 : {<span>{_.get(this.state.editCamera, 'phone', '')}<br/></span>}
                                카메라 주소 : {<span>{_.get(this.state.editCamera, 'cameraIp', '')}<br/></span>}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.cameraHandleClose} color="primary">
                                취소
                            </Button>
                            <Button onClick={() => {
                                handleRemove(this.state.editCamera);
                                this.cameraHandleClose()
                            }} color="primary" autoFocus>
                                확인
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {header.map((x, i) => (
                                <CustomTableCell key={`thc-${i}`}>{x.name}</CustomTableCell>
                            ))}
                            <CustomTableCell> 수정 </CustomTableCell>
                            <CustomTableCell> 삭제 </CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data !== undefined && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((user, index) => {
                                return row(
                                    user,
                                    index,
                                    header,
                                    startEditing,
                                    editIdx,
                                    handleChange,
                                    handleRemove,
                                    stopEditing
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow style={{height: 49 * emptyRows}}>
                                <TableCell colSpan={6}/>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    labelRowsPerPage="페이지 당 행 수"
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </UserCameraWrapper>
        );
    }
}

UserCamera.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserCamera);
