import React, {Component} from "react";
import styled from "styled-components";
import UserCamera from "../camera/user-camera";
import AddCamera from "../camera/add-camera";
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from "@material-ui/core/TextField";
import Input from '@material-ui/core/Input';
import {history} from "../../../history";
import UserList from "./userList";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import AccountCircle from '@material-ui/icons/AccountCircle';
import CameraEnhance from '@material-ui/icons/CameraEnhance';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CssBaseline from "@material-ui/core/CssBaseline";
import Notice from "../notice";
import RegionList from "./regionList";

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(800 + theme.spacing.unit * 3 * 2)]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    listMain: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(1000 + theme.spacing.unit * 3 * 2)]: {
            width: 1200,
            marginRight: 'auto',
        }
    },
    themeRoot: {
        flexGrow: 1
    }, paper: {
        width: 400,
        marginTop: theme.spacing.unit * 2,
        marginLeft: theme.spacing.unit * 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    listPaper: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    control: {
        padding: 40
    },
    userContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        width: 'auto'
    },
    cameraContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        width: '80%'
    },
    root: {/*
    display: 'flex',
    flexWrap: 'wrap',*/
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120
    },
    listFormControl: {
        margin: theme.spacing.unit * 2,
        minWidth: 120,
        alignItems: 'left'
    },
    textField: {
        marginTop: theme.spacing.unit,
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    menu: {
        width: '100%',
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
    },
});

const RegisterWrapper = styled.div`

`;

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userMessage: {
                type: "success",
                msg: ""
            },
            user: {
                name: "",
                phone: "",
                password: "",
                region: "",
                sensor: ""
            },
            cameraMessage: {
                type: 'success',
                msg: null,
            },
            camera: {
                cameraName: "",
                cameraPhone: "",
                cameraIp: "",
                public: false,
            },
            regionData: {},
            cityList: [],
            city: "",
            regionList: [],
            region: "",
            cameraData:
                [],
            cameraEditIdx:
                -1,
            userData:
                [],
            userEditIdx:
                -1,
            refreshing:
                false,
            userQuery:
                '',
            userColumnToQuery:
                'phone',
            cameraQuery:
                '',
            cameraColumnToQuery:
                'phone'
        }
        ;

        this._onUserTextFieldChange = this._onUserTextFieldChange.bind(this);
        this._onCameraTextFieldChange = this._onCameraTextFieldChange.bind(this);
        this._onUserSubmit = this._onUserSubmit.bind(this);
        this._onCameraSubmit = this._onCameraSubmit.bind(this);
    }

    updateData() {
        const {store} = this.props;
        this._loadUserData(store);
        this._loadCameras(store);
    }

    _loadUserData(store) {
        store.getUserList((res) => {
            this._setUserData(res);
        });
    }

    _setUserData(userData) {
        this.setState({userData});
    }

    _setRegionList(regionData) {
        this.setState({
            regionData
        })
    }

    _setCityList(regionData) {
        let cityList = new Set();
        regionData.map(data => cityList.add(_.get(data, 'city', '')));
        cityList = Array.from(cityList);
        this.setState({
            cityList
        });
    }

    _loadCameras(store) {
        store.getUserCameras((res) => {
            this._setCameras(res);
        });
    }

    _setCameras(cameraData) {
        this.setState({cameraData});
    }

    _loadRegion(store) {
        store.getRegionData((res) => {
            this._setRegionList(res);
            this._setCityList(res);
        });
    }


    componentWillMount() {
        const {store} = this.props;
        const token = store.loadTokenFromLocalStorage();
        this._loadRegion(store);
        this._loadCameras(store);
        this._loadUserData(store);
        const userID = _.get(token, "userId", "");
        if (userID !== "5bfb320b7bcbdd73d7351915") {
            history.push(`/`);
        }
    }

    _onUserSubmit(event) {
        event.preventDefault();
        const {user} = this.state;
        const {userData} = this.state;
        const {store} = this.props;
        store.createUserAccount(user, (err, result) => {
            if (err) {
                this.setState({
                    userMessage: {
                        type: "error",
                        msg: _.get(
                            err,
                            "error.message",
                            "계정을 만드는데 에러가 발생했습니다."
                        )
                    }
                });
            } else {
                this.setState({
                    userMessage: {type: "success", msg: "성공으로 계정이 만들어 졌습니다."},
                    user: {
                        name: "",
                        phone: "",
                        password: "",
                        regionNumber: "",
                        sensor: ""
                    }
                });
                let userResult = [];
                userResult.push(result);
                userData.map( (row, index) =>{
                    userResult.push(row);
                    return row;
                });
                this.setState({userData : userResult});
            }
        });
    }

    _onCameraSubmit(event) {
        event.preventDefault();
        const {store} = this.props;
        const {camera} = this.state;
        store.addCamera(camera, (err, result) => {
            if (err) {
                this.setState({
                    message: {type: 'error', msg: _.get(err, 'response.data.error.message', 'An error')}
                })
            } else {
                this.setState({
                    message: {type: 'success', msg: `${camera.name} 가 정상적으로 등록되었습니다.`},
                    camera: {
                        name: "",
                        public: false,
                    }
                });

            }
        });
    }

    _onUserTextFieldChange(event) {
        let {user} = this.state;
        const field = event.target.name;
        const value = event.target.value;
        user[field] = value;
        this.setState({
            user: user
        });
    }

    _onCameraTextFieldChange(event) {
        let {camera} = this.state;
        const field = event.target.name;
        const value = event.target.value;
        camera[field] = value;
        this.setState({
            camera: camera
        });
    }

    _handleChangeCity = name => event => {
        const value = event.target.value.toUpperCase();
        const regionList = this.state.regionData.filter(x =>
            x['city']
                .toUpperCase()
                .includes(value)
        );
        this.setState({[name]: value.toLowerCase()});
        this.setState({regionList});
    };
    _handleChangeRegion = name => event => {
        const value = event.target.value;
        let {user} = this.state;
        this.setState({[name]: event.target.value});
        const searchRegion = this.state.regionData.filter(x =>
            x['region']
                .toUpperCase()
                .includes(value)
        );
        const regionNumber = _.get(searchRegion[0], 'region_number', '');
        user['region'] = regionNumber;
        this.setState({
            user: user
        });
    };
    _onUserCancel = () => {
        this.setState({
            user: {
                name: "",
                phone: "",
                password: "",
                region: "",
                sensor: ""
            }
        });
    };
    _onCameraCancel = () => {
        this.setState({
            camera: {
                cameraName: "",
                cameraPhone: "",
                cameraIp: "",
                public: false,
            }
        });
    };

    cameraStartEditing = i => {
        this.setState({cameraEditIdx: i});
    };

    cameraStopEditing = cam => {
        const {store} = this.props;
        this.setState({cameraEditIdx: -1});
        store.updateUserCamera(cam);
    };

    cameraHandleChange = (e, name, phone, cameraName, cameraIp) => {
        const {value} = e.target;
        const data = this.state.cameraData.map((row, j) =>
            _.get(row, 'phone') === phone &&
            _.get(row, 'name') === cameraName &&
            _.get(row, 'cameraIp') === cameraIp
                ? {...row, [name]: value} : row
        );
        this.setState({
            cameraData: data
        });
    };

    cameraHandleRemove = cam => {
        const {store} = this.props;
        const cameraId = _.get(cam, "_id");
        const {cameraData} = this.state;
        let cameraResult = [];
        cameraData.map( (row, index) => {
            if(_.get(row, '_id', '') !== cameraId){
                cameraResult.push(row);
            }
            return row;
        });
        this.setState({cameraData : cameraResult});
        store.removeUserCamera(cameraId);
    };

    userStartEditing = i => {
        this.setState({userEditIdx: i});
    };

    userStopEditing = user => {
        const {store} = this.props;
        this.setState({userEditIdx: -1});
        store.updateUserInformation(user);
    };

    _regionDialogClose = () => {
        this.setState({
            regionDialog : false,
            newRegionCode : '',
            newRegionCity : '',
            newRegionName : '',
        });
    };

    userHandleChange = (e, name, phone) => {
        const {value} = e.target;
        const data = this.state.userData.map((row, j) =>
            _.get(row, 'phone') === phone ? {...row, [name]: value} : row
        );
        this.setState({
            userData: data
        });
    };

    userHandleRemove = user => {
        const {store} = this.props;
        const {userData} = this.state;
        const userId = _.get(user, "_id");
        let userResult = [];
        userData.map( (row, index) => {
            if(_.get(row, '_id', '') !== userId){
                userResult.push(row);
            }
            return row;
        });
        this.setState({userData : userResult});
        store.removeUserInformation(userId);
    };

    handleChangeUserSelect = event => {
        this.setState({userColumnToQuery: event.target.value});
    };

    handleChangeUserTextField = event => {
        this.setState({userQuery: event.target.value});
    };

    handleChangeCameraSelect = event => {
        this.setState({cameraColumnToQuery: event.target.value});
    };

    handleChangeCameraTextField = event => {
        this.setState({cameraQuery: event.target.value});
    };

    render() {
        const {classes} = this.props;
        const {user, userMessage} = this.state;
        return (
            <RegisterWrapper>
                <main className={classes.main}>
                    <CssBaseline/>
                    <Paper className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <AccountCircle/>
                        </Avatar>
                        <Typography component={"h1"} variant={"h5"}>
                            계정 등록
                        </Typography>
                        <form className={classes.userContainer} noValidate autoComplete="off"
                              onSubmit={this._onUserSubmit}>
                            {userMessage.msg ? (
                                userMessage.type === "success" ? (
                                    <Typography>{userMessage.msg}</Typography>
                                ) : (
                                    <Typography>{userMessage.msg}</Typography>
                                )
                            ) : null}
                            <FormControl margin={"normal"} required fullWidth>
                                <InputLabel htmlFor="name">사용자 이름</InputLabel>
                                <Input id="name" name="name" autoFocus
                                       value={_.get(user, "name", "")}
                                       onChange={this._onUserTextFieldChange}/>
                            </FormControl>
                            <FormControl margin={"normal"} required fullWidth>
                                <InputLabel htmlFor="phone">핸드폰 번호</InputLabel>
                                <Input id="phone" name="phone" autoFocus
                                       value={_.get(user, "phone", "")}
                                       onChange={this._onUserTextFieldChange}/>
                            </FormControl>
                            <FormControl margin={"normal"} required fullWidth>
                                <InputLabel htmlFor="password">비밀번호</InputLabel>
                                <Input id="password" name="password" autoFocus
                                       type={"password"}
                                       value={_.get(user, "password", "")}
                                       onChange={this._onUserTextFieldChange}/>
                            </FormControl>
                            <FormControl margin={"normal"} required fullWidth>
                                <InputLabel htmlFor="age-simple">군, 면, 시</InputLabel>
                                <Select
                                    value={this.state.city}
                                    onChange={this._handleChangeCity('city')}
                                    inputProps={{
                                        name: 'city',
                                        id: 'city',
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                >
                                    {this.state.cityList.length === 0 ? null : this.state.cityList.map((item, index) => (
                                        <MenuItem
                                            key={item}
                                            value={item}
                                        >
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>지역을 선택해주세요.</FormHelperText>
                            </FormControl>
                            <FormControl margin={"normal"} required fullWidth>
                                <InputLabel htmlFor="age-simple">세부주소</InputLabel>
                                <Select
                                    value={this.state.region}
                                    onChange={this._handleChangeRegion('region')}
                                    inputProps={{
                                        name: 'region',
                                        id: 'region',
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                >
                                    {this.state.regionList.length === 0 ? null :
                                        this.state.regionList.map((item, index) =>
                                            <MenuItem
                                                key={_.get(item, 'region', '')}
                                                value={_.get(item, 'region', '')}
                                            >
                                                {_.get(item, 'region', '')}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                                <FormHelperText>세부주소를 선택해주세요.</FormHelperText>
                            </FormControl>
                            <FormControl margin={"normal"} required fullWidth>
                                <InputLabel htmlFor="sensor">센서 번호</InputLabel>
                                <Input id="sensor" name="sensor" autoFocus
                                       value={_.get(user, "sensor", "")}
                                       onChange={this._onUserTextFieldChange}/>
                            </FormControl>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                계정 등록
                            </Button>
                            <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                onClick={this._onUserCancel}
                            >
                                취소
                            </Button>
                        </form>
                    </Paper>
                    <AddCamera store={this.props.store}
                               cameraData = {this.state.cameraData}
                               _setCameras = {this._setCameras.bind(this)}

                    />
                </main>
                <main className={classes.listMain}>
                    <CssBaseline/>
                    <Paper className={classes.listPaper}>
                        <Grid container justify="center" alignItems="center">
                            <Avatar className={classes.avatar}>
                                <CameraEnhance/>
                            </Avatar>
                        </Grid>
                        <Typography component="h1" variant="h5" gutterBottom align={"center"}>
                            등록된 카메라 리스트
                        </Typography>
                        <form className={classes.root} autoComplete="off">
                            <FormControl className={classes.listFormControl}>
                                <InputLabel htmlFor="user-search">검색범위</InputLabel>
                                <Select
                                    value={this.state.cameraColumnToQuery}
                                    onChange={this.handleChangeCameraSelect}
                                    inputProps={{
                                        name: 'cameraColumnToQuery',
                                        id: 'cameraColumnToQuery-data',
                                    }}
                                >
                                    <MenuItem value={"phone"}> 핸드폰 번호 </MenuItem>
                                    <MenuItem value={"name"}> 카메라 </MenuItem>
                                    <MenuItem value={"cameraIp"}> 카메라 주소 </MenuItem>
                                </Select>
                                <FormHelperText>검색하고 싶은 열을 선택하세요</FormHelperText>
                            </FormControl>
                            <TextField
                                id="userQuery-data"
                                label="검색"
                                className={classes.textField}
                                value={this.state.cameraQuery}
                                onChange={this.handleChangeCameraTextField}
                                margin="normal"
                            />
                        </form>
                        <UserCamera
                            store={this.props.store}
                            header={[
                                {name: "핸드폰 번호", prop: "phone"},
                                {name: "카메라 이름", prop: "name"},
                                {name: "카메라 주소", prop: "cameraIp"}
                            ]}
                            handleRemove={this.cameraHandleRemove}
                            startEditing={this.cameraStartEditing}
                            editIdx={this.state.cameraEditIdx}
                            stopEditing={this.cameraStopEditing}
                            handleChange={this.cameraHandleChange}
                            data={this.state.cameraQuery
                                ? this.state.cameraData.filter(x =>
                                    x[this.state.cameraColumnToQuery]
                                        .toUpperCase()
                                        .includes(this.state.cameraQuery.toUpperCase())
                                )
                                : this.state.cameraData}
                        />
                    </Paper>
                </main>
                <main className={classes.listMain}>
                    <CssBaseline/>
                    <Paper className={classes.listPaper}>
                        <Grid container justify="center" alignItems="center">
                            <Avatar className={classes.avatar}>
                                <AccountCircle/>
                            </Avatar>
                        </Grid>
                        <Typography component="h1" variant="h5" gutterBottom align={"center"}>
                            등록된 유저 리스트
                        </Typography>
                        <form className={classes.root} autoComplete="off">
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="age-simple">검색범위</InputLabel>
                                <Select
                                    value={this.state.userColumnToQuery}
                                    onChange={this.handleChangeUserSelect}
                                    inputProps={{
                                        name: 'userColumnToQuery',
                                        id: 'userColumnToQuery-data',
                                    }}
                                >
                                    <MenuItem value={"name"}> 유저 이름 </MenuItem>
                                    <MenuItem value={"phone"}> 핸드폰 번호 </MenuItem>
                                    <MenuItem value={"region"}> 지역 번호 </MenuItem>
                                    <MenuItem value={"sensor"}> 센서 번호 </MenuItem>
                                </Select>
                                <FormHelperText>검색하고 싶은 열을 선택하세요</FormHelperText>
                            </FormControl>
                            <TextField
                                id="userQuery-data"
                                label="검색"
                                className={classes.textField}
                                value={this.state.userQuery}
                                onChange={this.handleChangeUserTextField}
                                margin="normal"
                            />
                        </form>

                        <UserList
                            store={this.props.store}
                            header={[
                                {name: "유저 이름", prop: "name"},
                                {name: "핸드폰 번호", prop: "phone"},
                                {name: "지역번호", prop: "region"},
                                {name: "센서번호", prop: "sensor"}
                            ]}
                            handleRemove={this.userHandleRemove}
                            startEditing={this.userStartEditing}
                            editIdx={this.state.userEditIdx}
                            stopEditing={this.userStopEditing}
                            handleChange={this.userHandleChange}
                            data={this.state.userQuery
                                ? this.state.userData.filter(x =>
                                    x[this.state.userColumnToQuery]
                                        .toUpperCase()
                                        .includes(this.state.userQuery.toUpperCase())
                                )
                                : this.state.userData}
                        />
                    </Paper>
                </main>
                <Paper>
                    <main className={classes.listMain}>
                        <CssBaseline/>
                        <Paper className={classes.listPaper}>
                            <RegionList
                                store={this.props.store}
                                setRegionList={this._setRegionList.bind(this)}
                                setCityList={this._setCityList.bind(this)}
                            />
                        </Paper>
                    </main>
                </Paper>
                <Paper>
                    <main className={classes.listMain}>
                        <CssBaseline/>
                        <Paper className={classes.listPaper}>
                            <Notice store={this.props.store}/>
                        </Paper>
                    </main>
                </Paper>
            </RegisterWrapper>
        );
    }
}

Register.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Register);
